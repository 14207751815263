const actions = {
  SET_STATE: 'user/SET_STATE',
  G_LOGIN: 'user/G_LOGIN',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
}

export default actions
