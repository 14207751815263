import apiClient from 'services/axios'
import store from 'store'

export function googleLogin(email, token, photo) {
  return apiClient
    .post('/api/login', {
      email,
      credentials: {
        google: {
          id_token: token,
          image_url: photo,
        },
      },
    })
    .then(response => {
      console.log(response)
      if (response) {
        const accessToken = response.data.access_token
        const refreshToken = response.data.refresh_token
        const userId = response.data.user.id
        if (accessToken) {
          store.set('accessToken', accessToken)
          store.set('refreshToken', refreshToken)
          store.set('user', userId)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function login(email, password) {
  return apiClient
    .post('/api/login', {
      email,
      password,
    })
    .then(response => {
      if (response) {
        const accessToken = response.data.access_token
        const refreshToken = response.data.refresh_token
        const userId = response.data.user.id
        if (accessToken) {
          store.set('accessToken', accessToken)
          store.set('refreshToken', refreshToken)
          store.set('user', userId)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(email, password, name) {
  return apiClient
    .post('/api/register', {
      email,
      password,
      name,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  const user = store.get('user')
  return apiClient
    .get(`/api/get_user_details/${user}`)
    .then(response => {
      if (response) {
        const { accessToken } = response.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/auth/logout')
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => {
      console.log(err)
      store.remove('accessToken')
      return true
    })
}
